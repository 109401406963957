#home {
  background-image: url(../../assets/bghome.jpg);
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: repeat;

  .app__wrapper {
    padding: 0;

    .copyright {
      display: none;
    }
  }
}

.app__header {
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: row;

  padding: 6rem 2rem 0rem;

  @media screen and (min-width: 2000px) {
    padding-top: 8rem;
  }

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }

  @media screen and (max-width: 450px) {
    padding: 6rem 1rem 2rem;
  }
}

.app__header-info {
  flex: 0.65;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  margin: 0;
  padding: 5rem;

  @media screen and (max-width: 320px) {
    width: 100%;
    margin-right: 0rem;
    padding: 1rem;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    margin-right: 0rem;
    padding: 1.5rem;
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
    margin-right: 0rem;
  }
}

.app__header-badge {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;

  .badge-cmp,
  .tag-cmp {
    padding: 1rem 2rem;
    border-radius: 15px;
    flex-direction: row;
    width: auto;

    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  }

  .tag-cmp {
    flex-direction: column;
    margin-top: 2rem;

    p {
      width: 100%;
      text-transform: uppercase;
      text-align: center;
    }
  }

  @media screen and (max-width: 320px) {
    .tag-cmp {
      margin-top: 1rem;
    }
  }
}
